import React from 'react'
import { Button, Grid, Text, Title } from '../../../../youtalk-storybook/src/ui'
import {
  ButtonWrapper,
  Card,
  CardContainer,
  Content,
  DescriptionWrapper,
  FirstBlockImage,
  FourthBlockImage,
  ImageWrapper,
  InfoContainer,
  SecondBlockImage,
  ThirdBlockImage,
  TitleCard,
  TitleH3asH2style,
  Wrapper
} from './HelpChildren.styles'
import { TitleWrapper } from '../Common.styles'

export const HelpChildren = () => (
  <Wrapper>
    <section>
      <Grid>
        <Content>
          <CardContainer>
            <Card>
              <InfoContainer>
                <TitleWrapper>
                  <TitleH3asH2style>
                    Психологическая помощь детям при разводах
                  </TitleH3asH2style>
                </TitleWrapper>
                <DescriptionWrapper>
                  <Text.Large semiBold>
                    Нередко ментальное здоровье детей становится уязвимым от
                    развода родителей. Например, часто ребенок начинает думать,
                    что проблема в нем, что родители разводятся потому что он
                    «плохой» или «неправильный», начинает чувствовать огромную
                    вину. Или сталкивается с мыслями о том, что «папа/мама меня
                    больше не любит», когда один из родителей съезжает.
                  </Text.Large>
                  <Text.Large semiBold>
                    В такой период очень важно дать ребенку возможность открыто
                    говорить о своих переживаниях, получить помощь и поддержку
                    от близких и профессионалов. При этом важно не скрывать от
                    ребенка развод и не втягивать его в конфликты, раздел
                    имущества и обмен оскорблениями.
                  </Text.Large>
                </DescriptionWrapper>
              </InfoContainer>
              <ImageWrapper>
                <FirstBlockImage alt="Психологическая помощь детям при разводах" />
              </ImageWrapper>
            </Card>

            <Card>
              <ImageWrapper>
                <SecondBlockImage alt="психолог работает с детьми и родителями" />
              </ImageWrapper>
              <InfoContainer>
                <TitleWrapper>
                  <TitleCard>
                    Психолог работает как с детьми, так и с родителями, чтобы
                    создать благоприятные условия для детей в период адаптации к
                    новой ситуации
                  </TitleCard>
                </TitleWrapper>
                <DescriptionWrapper>
                  <Text.Large semiBold>
                    Психолог работает с детьми, используя игры, рисунки и
                    разговоры, чтобы помочь им выразить свои чувства и научиться
                    решать конфликты. Такая поддержка может способствовать
                    психологической адаптации детей и уменьшить возможные
                    негативные последствия развода на их психическое здоровье.
                  </Text.Large>
                </DescriptionWrapper>
              </InfoContainer>
            </Card>

            <Card>
              <InfoContainer>
                <TitleWrapper>
                  <TitleCard>
                    Еще один важный аспект — работа над коммуникацией
                  </TitleCard>
                </TitleWrapper>
                <DescriptionWrapper>
                  <Text.Large semiBold>
                    Психолог помогает бывшим супругам научиться эффективно
                    общаться в интересах детей. Это важно для создания здоровой
                    и стабильной обстановки, где дети могут чувствовать себя
                    безопасно и любимыми как до, так и после развода.
                  </Text.Large>
                </DescriptionWrapper>
              </InfoContainer>
              <ImageWrapper>
                <ThirdBlockImage alt="Еще один важный аспект — работа над коммуникацией" />
              </ImageWrapper>
            </Card>

            <Card>
              <ImageWrapper>
                <FourthBlockImage alt="С чем предстоит работать?" />
              </ImageWrapper>
              <InfoContainer>
                <TitleWrapper>
                  <Title.H2>С чем предстоит работать?</Title.H2>
                </TitleWrapper>
                <DescriptionWrapper>
                  <Text.Large semiBold>
                    Психологическая онлайн-помощь во время и после развода может
                    быть решающей для вашего психического здоровья и качества
                    жизни. Она предоставляет вам возможность понять и справиться
                    с эмоциональными и психологическими трудностями, связанными
                    с разводом, а также развить стратегии для создания
                    счастливого и здорового будущего. Не стесняйтесь обращаться
                    за поддержкой, потому что профессиональный психолог всегда
                    готов помочь вам в трудные моменты.
                  </Text.Large>
                </DescriptionWrapper>
                <ButtonWrapper>
                  <Button.NewPrimary href="/wizard/" size="large" type="link">
                    Подобрать психолога
                  </Button.NewPrimary>
                </ButtonWrapper>
              </InfoContainer>
            </Card>
          </CardContainer>
        </Content>
      </Grid>
    </section>
  </Wrapper>
)
