import React from 'react'
import messageIcon from '../../../../static/img/Razvod/message.svg'
import notepadIcon from '../../../../static/img/Razvod/notepad.svg'
import starIcon from '../../../../static/img/Razvod/star.svg'
import { Button, Grid, Text } from '../../../../youtalk-storybook/src/ui'
import {
  ButtonWrapper,
  Content,
  DescriptionWrapper,
  InfoContainer,
  ListImage,
  ListItem,
  ListWrapper,
  TitleH3asH2style,
  TitleWrapper,
  Wrapper
} from './HelpOfPsihologist.styles'

export const HelpOfPsihologist = () => (
  <Wrapper>
    <section>
      <Grid>
        <Content>
          <InfoContainer>
            <TitleWrapper color="white">
              <TitleH3asH2style>
                Помощь психолога в адаптации после развода
              </TitleH3asH2style>
            </TitleWrapper>
            <DescriptionWrapper color="white">
              <Text.Large semiBold>
                После развода, супруги сталкиваются с новой реальностью, которая
                может быть чрезвычайно сложной. Психологическая помощь после
                развода может помочь им справиться с эмоциональными трудностями
                и начать новую главу в своей жизни.
              </Text.Large>
              <Text.Large semiBold>
                Психолог может помочь после развода:
              </Text.Large>
            </DescriptionWrapper>
          </InfoContainer>
          <ListWrapper>
            <ListItem>
              <ListImage src={notepadIcon} />
              <Text.Large semiBold>
                Проанализировать и преодолеть чувства горя, утраты и
                одиночества. Оказать эмоциональную поддержку.
              </Text.Large>
            </ListItem>
            <ListItem>
              <ListImage src={messageIcon} />
              <Text.Large semiBold>
                Разработать стратегии построения новой жизни и установления
                новых отношений. Это может включать в себя планирование новых
                целей, создание новой рутины и восстановление социальных связей.
              </Text.Large>
            </ListItem>
            <ListItem>
              <ListImage src={starIcon} />
              <Text.Large semiBold>
                Разобраться с проблемами собственной самооценки и
                самоидентификации.
              </Text.Large>
            </ListItem>
          </ListWrapper>
          <ButtonWrapper>
            <Button.NewPrimary href="/wizard/" size="large" type="link">
              Подобрать психолога
            </Button.NewPrimary>
          </ButtonWrapper>
        </Content>
      </Grid>
    </section>
  </Wrapper>
)
